import {
    drOrderDetail,
    serviceGoodsWxPay,
    cancelServiceOrder
} from '@/utils/order.js';
import useClipboard from "vue-clipboard3"
import qrToast from '@/components/qr.vue'
import { provide, ref } from 'vue'
// 达人订单状态：1.未支付；2.已支付；3.课程已领取；4.已取消
export default {
    components: {qrToast},
    setup() {
        let qrtoast = ref(false);
        provide('qrtoast', qrtoast);
        return {
            qrtoast
        }
    },
    data() {
        return {
            isShowPage: false,
            isShow: false,
            checkXy: false,
            isPay: false,
            checkPayType: 'WXPAY',
            isToast: false,
            orderId: '',
            orderInfo: '',
            addressInfo: '',
            goodsInfo: [],
            showGroup: false,
            backgroundDiv: {
                backgroundImage: "url(" + require("@/assets/img/user/home/back.png") + ")",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
            },
        }
    },
    created() {
        this.orderId = this.$route.query.orderId;
        this.getOrderDetailFunc();
    },
    methods: {
        // 获取订单详情
        getOrderDetailFunc() {
            let data = {
                memberId: this.$cookies.get('memberId'),
                orderId: this.orderId
            }
            drOrderDetail(data).then(res => {
                console.log('detail', res)
                this.orderInfo = res.data;
                sessionStorage.setItem('orderInfo', JSON.stringify(res.data));
                this.isShow = false;
                setTimeout(() => {
                    this.isShowPage = true;
                }, 500)
                // 如果订单状态是1 显示底部菜单
                if (this.orderInfo.order_status == 1) {
                    this.isShow = true;
                    this.countDown();
                }
            })
        },
        // 过滤订单状态
        filterOrderStatusFunc(status) {
            let name = '';
            switch (status) {
                case 1:
                    name = '未支付';
                    break;
                case 2:
                    name = '已支付';
                    break;
                case 3:
                    name = '课程已领取';
                    break;
                case 4:
                    name = '已取消';
                    break;
            }
            return name;
        },
        // 复制订单号
        async copyFun(data) {
            try {
                const { toClipboard } = useClipboard()
                await toClipboard(data)
                this.$toast.success("已复制订单编号")
            } catch (e) {
                console.error(e)
            }
        },
        // 立即支付
        lijiPayFunc() {
            if (typeof WeixinJSBridge === 'undefined') { // 微信浏览器内置对象。参考微信官方文档
                if (document.addEventListener) {
                    document.addEventListener('WeixinJSBridgeReady', this.wxPayFunc(), false)
                } else if (document.attachEvent) {
                    document.attachEvent('WeixinJSBridgeReady', this.wxPayFunc())
                    document.attachEvent('onWeixinJSBridgeReady', this.wxPayFunc())
                }
            } else {
                console.log('准备调用微信支付')
                this.wxPayFunc();
            }
        },
        // 调起支付
        wxPayFunc() {
            let data = {
                memberId: this.$cookies.get('memberId'),
                orderId: this.orderInfo.orderNo
            }
            let _this = this;
            serviceGoodsWxPay(data).then(res => {
                console.log('微信支付', res)
                    //调用微信支付
                WeixinJSBridge.invoke(
                    'getBrandWCPayRequest', {
                        "appId": res.result.sdk_appid, //公众号名称，由商户传入
                        "timeStamp": res.result.sdk_timestamp, //时间戳，自1970年以来的秒数
                        "nonceStr": res.result.sdk_noncestr, //随机串
                        "package": res.result.sdk_package,
                        "signType": res.result.sdk_signtype, //微信签名方式：
                        "paySign": res.result.sdk_paysign //微信签名
                    },
                    function(res) {
                        if (res.err_msg == "get_brand_wcpay_request:ok") {
                            _this.$toast.success({
                                message: '支付成功！',
                                forbidClick: true,
                            });
                            window.location.reload();
                        }
                        if (res.err_msg == "get_brand_wcpay_request:cancel") {
                            _this.$toast.fail({
                                message: '交易取消！',
                                forbidClick: true,
                            })
                            setTimeout(() => {
                                window.location.reload();
                            }, 1500);
                        } else if (res.err_msg == "get_brand_wcpay_request:fail") {
                            _this.$toast('支付失败！');
                        }

                        setTimeout(() => {
                            _this.$toast.clear();
                        }, 1500);
                    }
                );
            })
        },
        // 企业微信号
        async copyWxFun() {
            try {
                const { toClipboard } = useClipboard()
                await toClipboard('18017657468')
                this.$toast.success("已复制企业微信号")
            } catch (e) {
                console.error(e)
            }
        },
        // 读取兑换内容
        readDhContFunc() {
            this.qrtoast = true;
        },
        // 取消订单
        cancelOrderFunc() {
            let data = {
                memberId: this.$cookies.get('memberId'),
                orderId: this.orderId
            }
            cancelServiceOrder(data).then(res => {
                console.log('取消申请', res)
                this.$toast('订单取消成功！');
                this.getOrderDetailFunc();
            })
        },
        // 补0
        timeFormat(param) {//小于10的格式化函数
            return param < 10 ? '0' + param : param;
        },
        countDown() {//倒计时函数
            var _that = this;
            // 获取当前时间，同时得到活动结束时间数组
            let newTime = new Date().getTime();
            let endTime = new Date(this.orderInfo.gmt_create).getTime() + 900000;
            // 如果活动未结束，对时间进行处理
            if (endTime - newTime > 0) {
                let time = (endTime - newTime) / 1000;
                // 获取分、秒
                let min = parseInt(time % (60 * 60 * 24) % 3600 / 60);
                let sec = parseInt(time % (60 * 60 * 24) % 3600 % 60);
                this.orderInfo['min'] = _that.timeFormat(min);
                this.orderInfo['sec'] = _that.timeFormat(sec);
            } else {//活动已结束，全部设置为'00'
                this.orderInfo['min'] = '00';
                this.orderInfo['sec'] = '00';
                this.orderInfo.order_status = 4;
            }
            setTimeout(_that.countDown, 1000);
        },
    },
}