<template>
  <div class="od" v-if="isShowPage">
    <div class="od-bg"></div>
    <div class="od-main">
      <div class="flex flex-between align-center od-main-one">
        <div class="flex flex-left align-center">
          <img v-if="orderInfo.order_status == 1" src="../../../assets/img/order/no_pay.png" alt="">
          <img v-if="orderInfo.order_status == 4" src="../../../assets/img/order/cancel_order.png" alt="">
          <img v-if="orderInfo.order_status != 1 && orderInfo.order_status != 4" src="../../../assets/img/order/payed.png" alt="">
          <span>{{filterOrderStatusFunc(orderInfo.order_status)}}</span>
        </div>
        <div class="od-main-one-time" v-if="orderInfo.order_status == 1">{{orderInfo.min + ':' + orderInfo.sec}} 自动取消订单</div>
        <div class="flex flex-center align-center od-main-one-readcon" v-if="orderInfo.order_status != 1 && orderInfo.order_status != 4" @click="readDhContFunc">查看兑换内容<img src="../../../assets/img/order/right_white.png" alt=""></div>
      </div>
      <div class="od-main-three">
        <div>
          <div class="flex flex-left align-center od-main-three-m">
            <div class="od-main-three-m-img">
              <img src="../../../assets/img/order/kecheng.jpeg" alt="" style="width: 100%;height:100%;object-fit: scale-down;">
            </div>
            <div class="od-main-three-m-info">
              <div class="od-main-three-m-info-name">
                <div class="od-main-three-m-info-name-tag" v-if="orderInfo.isSeckill == 1">限时抢购</div>流媒体营销密码与私域带货实战课程套装
              </div>
              <div class="od-main-three-m-info-guige" style="padding: 10px;"></div>
              <div class="flex flex-between od-main-three-m-info-price">
                <span class="od-main-three-m-info-price-s1">¥{{parseFloat(orderInfo.real_amount/100).toFixed(2)}}</span>
                <span class="od-main-three-m-info-price-s2">x1</span>
              </div>
            </div>
          </div>
        </div>
        <div class="od-main-four-line" style="margin-top: 15px;"></div>
        <div class="flex flex-between align-center od-main-four-price">
          <span class="od-main-four-price-l">实付款</span>
          <span class="od-main-four-price-r">¥{{parseFloat(orderInfo.real_amount/100).toFixed(2)}}</span>
        </div>
      </div>
      <div class="od-main-six">
        <div class="od-main-six-title">订单信息</div>
        <div class="flex flex-between align-center od-main-six-list">
          <span class="od-main-six-list-l">订单编号</span>
          <div class="flex flex-right align-center od-main-six-list-r">
            {{orderId}}<img src="../../../assets/img/order/copy.png" alt="" @click="copyFun(orderInfo.orderNo)">
          </div>
        </div>
        <div class="flex flex-between align-center od-main-six-list">
          <span class="od-main-six-list-l">下单时间</span>
          <div class="flex flex-right align-center od-main-six-list-r">
            {{orderInfo.gmt_create}}
          </div>
        </div>
        <div class="flex flex-between align-center od-main-six-list">
          <span class="od-main-six-list-l">付款时间</span>
          <div class="flex flex-right align-center od-main-six-list-r" v-if="orderInfo.payTime">
            {{orderInfo.payTime}}
          </div>
          <div class="flex flex-right align-center od-main-six-list-r" v-else>
            —— ——
          </div>
        </div>
        <div class="flex flex-between align-center od-main-six-list" v-if="orderInfo.order_status != 1 && orderInfo.order_status != 2 && orderInfo.order_status != 4">
          <span class="od-main-six-list-l">履约时间</span>
          <div class="flex flex-right align-center od-main-six-list-r" v-if="orderInfo.performanceTime">
            {{orderInfo.performanceTime}}
          </div>
          <div class="flex flex-right align-center od-main-six-list-r" v-else>
            —— ——
          </div>
        </div>
        <div class="flex flex-between align-center od-main-six-list" v-if="orderInfo.order_status == 4">
          <span class="od-main-six-list-l">取消时间</span>
          <div class="flex flex-right align-center od-main-six-list-r" v-if="orderInfo.performanceTime">
            {{orderInfo.performanceTime}}
          </div>
          <div class="flex flex-right align-center od-main-six-list-r" v-else>
            —— ——
          </div>
        </div>
        <div class="od-main-four-line" style="margin-top: 15px;" v-if="orderInfo.order_status == 3"></div>
        <div class="od-main-six-toast" v-if="orderInfo.order_status == 3">注：课程服务交付后将无法退款</div>
      </div>
    </div>
    <!-- 底部操作按钮 -->
    <van-popup v-model:show="isShow" position="bottom" round safe-area-inset-bottom :overlay="false" :lock-scroll="false">
      <div class="flex flex-right od-popup">
        <van-button plain round class="od-popup-btn" style="width: 100px;" @click="cancelOrderFunc">取消</van-button>
        <van-button round color="#E62129" class="od-popup-btn" v-if="orderInfo.order_status == 1" @click="isPay = true;">立即支付</van-button>
      </div>
    </van-popup>
    <!-- 支付申请 -->
    <van-popup v-model:show="isPay" round position="bottom" :safe-area-inset-bottom="true" :close-on-click-overlay="false">
      <div class="pay">
        <div class="pay-title">继续支付</div>
        <div class="pay-price">¥618</div>
        <van-radio-group v-model="checkPayType">
          <div class="flex flex-between align-center pay-type">
            <div class="pay-type-l">
              <img src="../../../assets/img/order/wx-pay.png" alt="">微信支付
            </div>
            <van-radio name="WXPAY" checked-color="#E62129"></van-radio>
          </div>
          <!-- <div class="flex flex-between align-center pay-type">
            <div class="pay-type-l">
              <img src="../../../assets/img/order/alipay-png.png" alt="">支付宝支付
            </div>
            <van-radio name="ALIPAY" checked-color="#E62129"></van-radio>
          </div> -->
        </van-radio-group>
        <div class="pay-btn">
          <van-button color="#E62129" round size="large" style="height:100%;" @click="lijiPayFunc">立即支付</van-button>
        </div>
        <div class="pay-close" @click="isPay = false;"></div>
      </div>
    </van-popup>
    <!-- 提示 -->
    <van-popup round position="center" v-model:show="isToast">
      <div class="od-toastmain">
        <div class="flex flex-center align-center od-toastmain-title">
          <span></span>
          提示
          <span></span>
        </div>
        <div class="od-toastmain-msg">是否放弃本次付款</div>
        <div class="od-toastmain-btn">
          <van-button class="od-toastmain-btn-list" plain type="default">放弃</van-button>
          <!-- <van-button class="od-toastmain-btn-list" plain type="default" color="#E62129">继续支付</van-button> -->
          <van-button class="od-toastmain-btn-list" plain type="default" color="#E62129">确认</van-button>
        </div>
      </div>
    </van-popup>
    <!-- 弹窗企业微信 -->
    <van-popup
      v-model:show="showGroup"
      position="center"
      :style="{ width: '80%' }"
      :close-on-click-overlay="false"
      class="add-serve-popup"
      closeable
      round
    >
      <div class="bottom" :style="backgroundDiv">
        <div class="welcome">
          <p class="flex flex-center align-center">加灵龙企业客服微信</p>
          <p class="flex flex-center align-center">
            领取更多活动优惠，一起讨论如何更省钱
          </p>
        </div>
        <div class="qr-code">
          <img src="@/assets/img/user/home/enterprise.jpg" />
        </div>
        <p class="qr-bottom">截图此二维码，用微信扫码关注企业客服</p>
        <p class="copy" @click="copyWxFun">复制企业微信号</p>
      </div>
    </van-popup>
    <!-- 二维码 -->
    <qrToast :qrtoast="qrtoast"></qrToast>
  </div>
</template>
<script src="./index.js"></script>
<style>
  .od-operation-main-bot-three .van-checkbox__label {
      color: #60656E;
  }
</style>
<style lang="scss" scoped>
  @import "./index.scss";
</style>