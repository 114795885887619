<template>
    <div>
      <van-popup
        v-model:show="showPhone"
        position="center"
        :style="{ width: '100%' }"
        :close-on-click-overlay="false"
        class="user-phone-popup"
      >
        <div class="info-box">
          <div class="close-img" @click="showPhone = false">
            <img src="@/assets/img/user/home/close.png" />
          </div>
          <div class="code flex align-center flex-center">
            <p></p>
            您已完成了课程的报名
            <p></p>
          </div>
          <div class="center flex align-center">
            <p>
                请截图本页面，并扫码进入DNS小程序领取<br/>
                您的专属课程包，开启从小白到达人的神奇之旅!<br/>
                完成课程后，您还将获得高价值红酒盲盒!
            </p>
          </div>
          <div class="pic">
            <img id="picurl" src="@/assets/img/loginRegister/dns_qr.png" />
          </div>
          <div class="flex flex-center align-center btn">
            保存至相册
            <img class="btn-picurl" src="@/assets/img/loginRegister/dns_qr.png" />
          </div>
          <p class="b-t">关闭此页面后，可以在订单列表继续查询</p>
        </div>
      </van-popup>
    </div>
</template>
<script>
  import useClipboard from "vue-clipboard3"
  import { inject } from 'vue'
  export default {
    setup() {
      let showPhone = inject('qrtoast')
      return {
        showPhone
      }
    },
    data() {
      return {}
    },
    created() {},
    methods: {
      async copyFun() {
          try {
              const { toClipboard } = useClipboard()
              await toClipboard('灵龙惠生活')
              this.$toast.success("已复制公众号名称")
          } catch (e) {
              console.error(e)
          }
      },
      // 下载
      downloadIamge(name) {
        var image = new Image();
        //跨域会导致a标签在部分浏览器中会直接打开新标签页 解决跨域 Canvas 污染问题
        image.setAttribute("crossOrigin", "anonymous");
        image.onload = function () {
          var canvas = document.createElement("canvas");
          canvas.width = image.width;
          canvas.height = image.height;
  
          var context = canvas.getContext("2d");
          context.drawImage(image, 0, 0, image.width, image.height);
          var url = canvas.toDataURL("image/png");
  
          // 生成一个a元素
          var a = document.createElement("a");
          // 创建一个单击事件
          var event = new MouseEvent("click");
  
          // 将a的download属性设置为我们想要下载的图片名称，若name不存在则使用‘下载图片名称’作为默认名称
          a.download = name || "下载图片名称";
          // 将生成的URL设置为a.href属性
          a.href = url;
  
          // 触发a的单击事件
          a.dispatchEvent(event);
        };
  
        image.src = document.getElementById('picurl').src;
      },
      saveImgFunc() {
        this.$toast('长按保存小程序码到相册');
      }
    }
  }
  </script>
  <style lang="scss">
  .user-phone-popup {
      width: 280px !important;
      top: 155px !important;
      transform: translateX(-50%) !important;
      background: none !important;
      overflow-y: unset !important;
      .info-box {
        background: #ffffff;
        -webkit-border-radius: 8px;
        border-radius: 8px;
        position: relative;
        padding: 20px 0 0;
        margin: 0 auto;
        position: relative;
        .close-img {
          width: 24px;
          height: 24px;
          position: absolute;
          top: -34px;
          right: 0;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .code {
          height: 21px;
          font-size: 15px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 21px;
          text-align: center;
          margin-bottom: 15px;
          p {
            background: #e62129;
            width: 8px;
            height: 1.5px;
          }
          p:nth-child(1) {
            margin-right: 5px;
          }
          p:nth-child(2) {
            margin-left: 5px;
          }
        }
        .center {
          background: rgba(228,126,17,0.08);
          padding: 10px 12px;
          p:nth-child(1) {
            font-size: 11px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #E47E11;
            line-height: 16px;
            margin-right: 4px;
            text-align: center;
          }
        }
        .pic {
          text-align: center;
          margin-top: 15px;
          overflow: hidden;
          img {
            width: 180px;
            height: 180px;
            transform: scale(1.15);
          }
        }
        .btn{
            position: relative;
            width: 150px;
            height: 36px;
            color: #666666;
            font-size: 14px;
            font-weight: 400;
            text-align: center;
            border-radius: 36px;
            margin: 0 auto;
            margin-top: 30px;
            border: 1px solid #CCCCCC;
            &-picurl{
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              z-index: 1;
              width: 100%;
              height: 100%;
              opacity: 0;
              overflow: hidden;
            }
        }
        .b-t {
          height: 14px;
          font-size: 10px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #E62129;
          line-height: 14px;
          text-align: center;
          padding: 9px 0px 26px;
        }
      }
    }
  </style>