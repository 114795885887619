import axios from "./request"
// 获取收货地址
export const getOrderList = (data) => {
    return axios({
        url: "/api/member/selectOrderUnionEntityAndService",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

// 下单页面获得可用积分和优惠券
export const getYhInfo = (data) => {
    return axios({
        url: "/api/weixin/mall/availableBenefit",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

// 创建订单
export const createOrder = (data) => {
    return axios({
        url: "/api/weixin/mall/submitOrder",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

// 获取运费
export const getFreight = (data) => {
    return axios({
        url: "/api/weixin/mall/getFreight",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

// 获取默认地址
export const getDetaultAddress = (data) => {
    return axios({
        url: "/api/member/getDefaultAddress",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

// 订单详情
export const getOrderDetail = (data) => {
    return axios({
        url: "/api/member/getGoodsOrder",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

// 获取个人信息
export const getUserInfo = (data) => {
    return axios({
        url: "/api/member/getweixininfo",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

// 取消订单
export const cancelOrder = (data) => {
    return axios({
        url: "/api/member/ordercancel",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

// 确认收货
export const goodReceived = (data) => {
    return axios({
        url: "/api/weixin/mall/goodReceived",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

// 申请退款
export const orderRefund = (data) => {
    return axios({
        url: "/api/weixin/mall/orderRefund",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

// 取消售后
export const cancelRefund = (data) => {
    return axios({
        url: '/api/member/cancelAfterSale',
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

// 微信支付
export const wxPay = (data) => {
    return axios({
        url: '/api/weixin/mall/wexinPay',
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

// 支付宝支付
export const aliPay = (data) => {
    return axios({
        url: '/api/weixin/mall/aliPay',
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

// 获取物流信息
export const getLogistics = (data) => {
    return axios({
        url: '/api/member/orderExpressDetail',
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

// 购买课程 下单 memberId=会员编号 goodsId=商品编号(传0) shareMemberId=分享者编号
export const submitServiceOrder = (data) => {
    return axios({
        url: '/api/weixin/mall/submitServiceOrder',
        method: 'post',
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            requestReturn: true
        }
    })
}

// 购买课程支付
// memberId=会员编号
// orderId=订单号(取submitServiceOrder返回的orderNo)
export const serviceGoodsWxPay = (data) => {
    return axios({
        url: '/api/weixin/mall/serviceGoodsWxPay',
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

// 达人订单列表
/**
 * 
 * @param {
 * memberId=会员编号
 * orderStatus=99(查已履约)，订单状态：1.未支付；2.已支付；3.初级课程已完结；4.本地团购达人已达成；5.实物商品已签收；6.已取消
 * pageNo=页码
 * pageSize=每页记录数} data 
 * @returns 
 */
export const shopServiceGoodOrderList = (data) => {
    return axios({
        url: '/api/member/shopServiceGoodOrderList',
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

/**
 * api/member/orderdetail
 * memberId=会员编号
 * orderId=订单编号
 */
 export const drOrderDetail = (data) => {
    return axios({
        url: '/api/member/orderdetail',
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

/**
 * 取消服务订单
 * api/member/cancelServiceOrder
 * memberId=会员编号
 * orderId=订单号
 */
 export const cancelServiceOrder = (data) => {
    return axios({
        url: '/api/member/cancelServiceOrder',
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}


/**
 * api/member/addUserInfo
orderId=订单编号
nickName=真实姓名
phone=联系电话
sex=性别，传中文
tiktokNum=抖音号
province=住家省
city=住家市
county=住家区
workProvince=工作省
workCity=工作市
workCounty=工作区
省市区传中文
 */
export const addUserInfo = (data) => {
    return axios({
        url: '/api/member/addUserInfo',
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}